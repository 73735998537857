define("discourse/plugins/hosted-site/discourse/initializers/extend-invited", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators"], function (_exports, _pluginApi, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const PLUGIN_ID = "hosted-site";
  function initialize(api) {
    var _dec, _obj;
    api.modifyClass("controller:user-invited-show", (_dec = (0, _decorators.default)("currentUser.admin", "model.remaining_users"), (_obj = {
      pluginId: PLUGIN_ID,
      canBulkInvite(admin, remainingUsers) {
        return admin && (remainingUsers === undefined || Number(remainingUsers) > 1);
      }
    }, (_applyDecoratedDescriptor(_obj, "canBulkInvite", [_dec], Object.getOwnPropertyDescriptor(_obj, "canBulkInvite"), _obj)), _obj)));
  }
  var _default = _exports.default = {
    name: "extend-invited",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.8.40", api => initialize(api, container));
    }
  };
});