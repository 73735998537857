define("discourse/plugins/hosted-site/discourse/initializers/anon-init", ["exports", "@ember/runloop", "jquery", "discourse-common/lib/get-owner", "discourse-common/utils/decorators"], function (_exports, _runloop, _jquery, _getOwner, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = {
    name: "apply-anon-init",
    initialize() {
      const GlobalNoticeComponent = (0, _getOwner.getRegister)(this).lookupFactory("component:global-notice");
      if (GlobalNoticeComponent) {
        var _dec, _obj;
        GlobalNoticeComponent.class.reopen((_dec = (0, _decorators.on)("didInsertElement"), (_obj = {
          _hideOtherAlerts() {
            (0, _runloop.schedule)("afterRender", () => {
              if ((0, _jquery.default)(".alert .trial-expired-message").length > 0 || (0, _jquery.default)(".alert .discourse-hosting-readonly").length > 0) {
                (0, _jquery.default)(".alert-read-only").hide();
                (0, _jquery.default)(".alert-too-few-topics").hide();
              }
            });
          }
        }, (_applyDecoratedDescriptor(_obj, "_hideOtherAlerts", [_dec], Object.getOwnPropertyDescriptor(_obj, "_hideOtherAlerts"), _obj)), _obj)));
      }
    }
  };
});