define("discourse/plugins/hosted-site/discourse/controllers/admin-invite", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "discourse/lib/ajax", "discourse/lib/url", "discourse/lib/waving-hand-url", "discourse/mixins/name-validation", "discourse/mixins/password-validation", "discourse-common/lib/get-url", "discourse-common/utils/decorators", "I18n"], function (_exports, _controller, _object, _computed, _ajax, _url, _wavingHandUrl, _nameValidation, _passwordValidation, _getUrl, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AdminInvite = _exports.default = (_dec = (0, _computed.alias)("model.admin"), _dec2 = (0, _computed.alias)("model.username"), _dec3 = (0, _computed.alias)("model.name"), _dec4 = (0, _computed.or)("passwordValidation.failed", "nameValidation.failed", "usernameErrorMessage"), _dec5 = (0, _decorators.default)("accountUsername", "usernameError"), _dec6 = (0, _decorators.default)(), _dec7 = (0, _decorators.default)("model.email"), _dec8 = (0, _decorators.default)("redirectTo"), (_class = class AdminInvite extends _controller.default.extend(_passwordValidation.default, _nameValidation.default) {
    constructor() {
      super(...arguments);
      _defineProperty(this, "passwordRequired", true);
      _defineProperty(this, "redirected", false);
      _defineProperty(this, "usernameError", void 0);
      _defineProperty(this, "lastAttemptedUsername", void 0);
      _initializerDefineProperty(this, "admin", _descriptor, this);
      _initializerDefineProperty(this, "accountUsername", _descriptor2, this);
      _initializerDefineProperty(this, "accountName", _descriptor3, this);
      _initializerDefineProperty(this, "submitDisabled", _descriptor4, this);
    }
    usernameErrorMessage(username, error) {
      if (error && username === this.lastAttemptedUsername) {
        return error;
      }
    }
    wavingHandURL() {
      return (0, _wavingHandUrl.wavingHandURL)();
    }
    welcomeTitle() {
      return _I18n.default.t("invites.welcome_to", {
        site_name: this.siteSettings.title
      });
    }
    yourEmailMessage(email) {
      return _I18n.default.t("admin_invite.your_email", {
        email
      });
    }
    redirectHref(redirectTo) {
      return (0, _getUrl.default)(redirectTo || "/");
    }
    async submit() {
      this.lastAttemptedUsername = this.accountUsername;
      const result = await (0, _ajax.ajax)({
        url: (0, _url.userPath)(`admin-invite/${this.get("model.token")}.json`),
        type: "PUT",
        data: {
          username: this.accountUsername,
          name: this.accountName,
          password: this.accountPassword,
          timezone: moment.tz.guess()
        }
      });
      if (result.success) {
        this.set("redirectTo", result.redirect_to);
        this.set("redirected", true);
        _url.default.redirectTo(this.redirectHref);
      } else {
        if (result.errors && result.errors.password && result.errors.password.length > 0) {
          this.rejectedPasswords.pushObject(this.accountPassword);
          this.rejectedPasswordsMessages.set(this.accountPassword, result.errors.password[0]);
        }
        if (result.errors && result.errors.username && result.errors.username.length > 0) {
          this.set("usernameError", result.errors.username[0]);
        }
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "admin", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "accountUsername", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "accountName", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "submitDisabled", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "usernameErrorMessage", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "usernameErrorMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "wavingHandURL", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "wavingHandURL"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "welcomeTitle", [_decorators.default], Object.getOwnPropertyDescriptor(_class.prototype, "welcomeTitle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "yourEmailMessage", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "yourEmailMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "redirectHref", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "redirectHref"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype)), _class));
});